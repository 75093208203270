import { UserProtectedRoute } from "./UserProtectedRoute";
import ProfileForm from '../pages/quiz/Quiz';
import Welcome from '../pages/quiz/Welcome';
import { IndexLayout } from '../components/layout/IndexLayout'


// Маршруты для кабинета
const userRoutes = [
  {
    path: "/",
    element: <UserProtectedRoute />, // Оборачиваем компонент в ProtectedRoute если токена нет мы просто игнорируем 
    //всё что ниже и переходим в login/
    children: [

      {
        path: "/quiz",
        element: <ProfileForm />,
      },

      {
        path: "/welcome",
        element: <Welcome />,
      },






      // my_cabinet ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      {
        path: "/my_cabinet",
        element: <IndexLayout />,
        children: [




          // ~~~~~~~~~~~~~~~ курсы 
          {
            path: ":url_course/",
            lazy: async () => {
              let { Folders } = await import("../pages/cabinet/folders/Folders")
              return { Component: Folders }
            }
          },




          // ~~~~~~~~~~~~~~~ курс -> урок 
          {
            path: "curs/:url_course/:url_folder/:url_lesson",
            lazy: async () => {
              let { LessonOne } = await import("../pages/cabinet/lessons/LessonOne")
              return { Component: LessonOne }
            }
          },




          // ~~~~~~~~~~~~~~~ клуб -> урок 
          {
            path: ":url_club/:url_course/:url_folder/:url_lesson",
            lazy: async () => {
              let { LessonOne } = await import("../pages/cabinet/lessons/LessonOne")
              return { Component: LessonOne }
            }
          },








          // ~~~~~~~~~~~~~~~ клуб -> уроки 
          {
            path: ":url_club/:url_course/:url_folder/",
            lazy: async () => {
              let { LessonsList } = await import("../pages/cabinet/lessons/LessonsList")
              return { Component: LessonsList }
            }
          },

          // ~~~~~~~~~~~~~~~ клуб -> уроки 
          {
            path: ":url_course/:url_folder/",
            lazy: async () => {
              let { LessonsList } = await import("../pages/cabinet/lessons/LessonsList")
              return { Component: LessonsList }
            }
          },








          // ~~~~~~~~~~~~~~~ Начальный урок
          {
            path: "introductory_lesson",
            lazy: async () => {
              let { LessonIntroductory } = await import("../pages/cabinet/lessons/LessonIntroductory")
              return { Component: LessonIntroductory }
            }
          },


          // ~~~~~~~~~~~~~~~ Мой кабинет
          {
            path: "",
            lazy: async () => {
              let { MyCabinet } = await import("../pages/cabinet/my_cabinet/MyCabinet")
              return { Component: MyCabinet }
            }
          },


          // ~~~~~~~~~~~~~~~ Оплата
          {
            path: "pay/:url/",
            lazy: async () => {
              let { PaymentPay } = await import("../pages/cabinet/pay/PaymentPay")
              return { Component: PaymentPay }
            }
          },


          // ~~~~~~~~~~~~~~~ Нужно удалить этот пункт  
          {
            path: "users",
            lazy: async () => {
              let { Users } = await import("../pages/admin/users/Users")
              return { Component: Users }
            }
          }

        ]

      },
    ],
  },
];


export default userRoutes 
