import './style.scss';
import { NavLink, Link } from 'react-router-dom'
import logo from './images/logo.svg'
import { useState, useEffect } from 'react'
import { ButtonMobileMenu } from '../buttonmobilemenu/ButtonMobileMenu'
import { CSSTransition } from 'react-transition-group'

const AdmNavbar = () => {

  const [menu, setMenu] = useState(false)

  const [windowSize, setWindowSize] = useState([
    window.innerWidth
  ]);

  // Вычисляем ширину сайта
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth])

    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // Если <= 800 то выключаем меню  
  useEffect(() => {
    if (windowSize[0] <= 800) {
      setMenu(false)
    }
    else {
      setMenu(true)
    }
  }, [windowSize[0]])

  // Кнопка всплывания меню
  const clickMenu = () => {
    setMenu(!menu)
  }

  // Назваине класса для ширины появления мобильного меню
  const classna = 'admnavbar xx';

  return (
    <>
      <div className="admin_header">
        <div className="admin_header_container">
          <Link to="/adm/new_course" className="logo" id="logo"><img src={logo} /> </Link>

          <CSSTransition in={menu} timeout={300}
            classNames='alert' unmountOnExit>
            <ul className="admin_menu">
              <li><NavLink to="/adm/new_tag">новый <br />тег</NavLink></li>
              <li><NavLink to="/adm/new_lesson">новый <br />урок</NavLink></li>
              <li><NavLink to="/adm/new_folder">новая <br />папка</NavLink></li>
              <li><NavLink to="/adm/new_course">новый <br />курс</NavLink></li>
              <li><NavLink to="/adm/new_club">новый <br />клуб</NavLink></li>

              <li><NavLink to="/adm/update_lessons">редактировать/<br />удалить урок</NavLink></li>
              <li><NavLink to="/adm/update_folders">редактировать/<br /> удалить папку</NavLink></li>
              <li><NavLink to="/adm/update_courses">редактировать/<br />удалить курс</NavLink></li>
              <li><NavLink to="/adm/update_clubs">редактировать/<br /> удалить клуб</NavLink></li>
            </ul>
          </CSSTransition>

          <ButtonMobileMenu clickMenu={clickMenu} classna={classna} />
        </div>
      </div>

      <div className="menu_cases">
        <NavLink to="/adm/users">Пользователи</NavLink>
        <NavLink to="/adm/new_stories">new stories</NavLink>
        <NavLink to="/adm/update_articles">редактировать/<br />удалить статью</NavLink>
        <NavLink to="/adm/new_article">новая <br />статья</NavLink>
        <NavLink to="/adm/order">заказы</NavLink>
      </div>
    </>
  )
}

export { AdmNavbar }
